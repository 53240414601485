@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=block');

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: none;
}

::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.3);
}

.mat-mdc-dialog-actions {
    justify-content: end !important;
}

.mat-mdc-dialog-content {
    text-align: center !important;
}

.mat-mdc-menu-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    background: hsl(0, 0%, 95%);
}

.mat-mdc-menu-item .mat-icon {
    margin-right: 0 !important;
    color: #001024 !important;
}

#main-container {
	width: 100vw;
	height: 100vh;
}

#navbar {
	background: #009688;
}
@media (max-width: 576px) {
	.navbar-top {
		z-index: 10;
		position: fixed;
		top: 0;
		width: 100%;
	}
	#messages{
		padding-top: 4rem;
		padding-bottom: 1rem;
	}
}

.dropdown-toggle::after {
	display: none;
}

.chat-list-item {
	background: white;
	cursor: pointer;
}

.chat-list-item:hover {
	background: hsl(0, 0%, 95%);
}

.chat-list-item:active {
	background: hsl(0, 0%, 85%);
}

.chat-list-item.active {
	background: hsl(0, 0%, 90%);
}

.chat-list-item .chat-details {
	width: 60%;
}

.chat-list-item.unread .name,
.chat-list-item.unread .last-message {
	font-weight: bold;
}

.chat-list-item .last-message,
#message-area #navbar #details {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#message-area {
	border-left: 1px solid white;
}

#message-area .overlay {
	background-color: #f0f2f5;
	background-image: url("assets/wpbgnc.jpg");
	background-repeat: no-repeat;
	background-size: 35%;
	background-position: center;
}

#input-area {
	/*position: fixed;*/
	/*bottom: 0;*/
	/*width: 100%;*/
	background: hsl(0, 0%, 95%);
}

#input-area #input {
	outline: none;
}

.message-item {
	position:relative;
	max-width: 75%;
	word-break: break-word;
}
.message-item.self {
	background: #dcf8c6!important;
}
.message-item .number {
	color: #1f7aec !important;
}
.message-item .options {
	position: absolute;
	top: 0;
	right: -3px;
	opacity: 0;
	transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
}
.message-item:hover .options {
	opacity: 1;
	right: 0;
}

#messages {
	flex: 1!important;
	background-color: #efeae2;
	background-image: url("assets/wpbg.png");
	background-size: 35%;
	overflow: auto;
}

#profile-settings {
	position: absolute;
	top: 0;
	left: -110%;
	background: hsl(0, 0%, 95%);
	transition: all 0.2s ease-in;
	-moz-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
}

#profile-pic {
	cursor: pointer;
	position: relative;
	width: 200px;
}

.profile-input {
	border-bottom: 2px solid transparent  !important;
	outline: none;
}

.profile-input:focus {
	border-bottom-color: hsl(0, 0%, 50%) !important;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
